@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
.iframe-height {
  height: calc(100rem - 780px);
}
.pt-login{  
  padding-top: 10%;

}
@media only screen and (min-width: 2130px) {
  .iframe-height {
    height: calc(100rem - 819px);
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .iframe-height {
    height: calc(100rem - 819px);
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1600px) {
  .iframe-height {
    height: calc(100rem - 1018px);
  }
  .pt-login{
    padding-top: 6% !important;
  }
}
@media only screen and (min-width: 1066px) and (max-width: 1300px) {
  .iframe-height {
    height: calc(100rem - 819px);
  }
  .pt-login{
    padding-top: 7% !important;
  }
}
@media only screen and (max-width: 1200px) {
  .iframe-height {
    height: calc(100rem - 1046px);
  }
  .pt-login{
    padding-top: 7% !important;
  }
}
.root-div {
  background-color: #fff !important;
  height: 100vh;
  width: 100%;
}
.bg-color {
  height: 100%;
  width: 100%;
  background-image: url(/static/media/bg-login-sfl.ad4ae96e.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
/* .bg-color-upper {
  background-image: linear-gradient(
    rgba(149, 210, 40, 0.2),
    rgba(149, 210, 40, 0)
  );
  height: 100vh;
  width: 100%;
} */
.btn-submit-contained {
  border-radius: 0px !important;
  background-color: #95d228 !important;
  border: 2px solid #95d228 !important;
  color: #fff !important;
  font-size: 1.4em !important;
    font-family: sans-serif;
    font-weight: 700;
}
.btn-submit-contained:hover {
  cursor: pointer;
   box-shadow: 5px 5px 0px rgb(149 210 40 / 30%) !important
}
.login-title {
  text-align: center;
  color: #000 !important;
  font-weight: bold !important;
  position: relative;
  display: block;
  top: 5vw;
}
.input-div-email {
  position: relative;
  display: block;
}
.input-div-pwd {
  position: relative;
  display: block;
}
/* .MuiInputBase-input.MuiOutlinedInput-input{
  border-radius:0px !important;
  border: 2px solid #000 !important;
}
.MuiInputBase-input.MuiOutlinedInput-input:focus{
  border-radius:0px !important;
  border: 2px solid #95d228 !important;
} */
.label#outlined-required-label {
  color: #95d228 !important;
}
.MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 0px !important;
  border-color: #fff !important; 
  border-width: 2px !important;
  color: #fff !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1v4ccyo {
  border: 2px solid #fff !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
  color: #fff;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 2px solid #fff !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #95d228 !important;
  border-width: 2px !important;
  border-width: 2px !important;
}
.css-bpnhhg .MuiDrawer-paper,
.css-eil3g9 .MuiDrawer-paper,
.mui-fixed {
  background-color: #1f1f1f !important;
}
.css-uqx0qm-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #1f1f1f !important;
  color: #fff !important;
}
.css-bpnhhg .MuiDrawer-paper,
.css-eil3g9 .MuiDrawer-paper,
.mui-fixed {
  background-color: #000 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #fff;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-focusVisible {
  background-color: #95d228 !important;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #000 !important;
}
.bgcolor-5C870E {
  background-color: #5c870e !important;
  color: #fff !important;
  border-radius: 0px !important;
}
.bgcolor-5C870E:hover {
  cursor: pointer;
  background-color: #fff !important;
  border: 3px solid #5c870e !important;
  color: #5c870e !important;
  border-radius: 0px !important;
}
.bgcolor-4F4F4F {
  background-color: #4f4f4f !important;
  color: #fff !important;
  border-radius: 0px !important;
}
.bgcolor-4F4F4F:hover {
  cursor: pointer;
  background-color: #fff !important;
  border: 3px solid #4f4f4f !important;
  color: #4f4f4f !important;
  border-radius: 0px !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #000 !important;
}
.color-fff {
  color: #fff !important;
}
.icon-size-header {
  width: 35px !important;
  height: 35px !important;
}
.inner-div {
  width: 100%;
  margin: 0px auto;
  padding-left: calc(64px + 1px);
}
.expand-icon {
  top: 0px;
  position: relative;
  margin-right: 2px;
}
.home-icon-expand {
  margin-left: -8px !important;
  position: absolute;
}
.home-icon-expand-sub-li {
  left: 5x !important;
  position: absolute;
}
.icon-div {
  width: 25px !important;
  height: 25px !important;
  padding: 1px;
  border-radius: 50% !important;
  background-color: #fff !important;
}
.icon-div-reports {
  width: 20px !important;
  height: 20px !important;
  padding: 1px;
  border-radius: 50% !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
}
.bg-selected-report {
  border: 3px solid #89c53b !important;
}
.icon-size-header {
  width: 35px !important;
  height: 35px !important;
}
.icon-name-sidebar {
  top: 2px;
  position: relative;
  color: #fff !important;
}
.ml-open-drawer-user {
  margin-left: 11.5% !important;
}
@media only screen and (min-width: 1600px) {
  .ml-open-drawer-user {
    margin-left: 9% !important;
  }
}
.hover-icon-div:hover {
  color: #89c53b !important;
  cursor: pointer !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1l8j5k8 {
  background-color: #000 !important;
}
.navbar-header {
  height: 66px;
  width: 96.2%;
  background: #000;
  display: flex;
  align-items: center;
  font-family: var(--lato);
  position: fixed;
  top: 0;
  left: 4.2%;
  z-index: 1000;
}
.avatar {
  width: 46px;
  height: 46px;
  position: relative;
  top: -6px;
  left: 3px;
  object-fit: cover;
  /* border-radius: 50%; */
}
.text-right {
  text-align: right;
}
.pr-header {
  padding-right: 20px;
}
.notifcation {
  position: absolute;
  top: 7px;
  right: 83px;
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  border: 2px solid #000;
  background: #ffae00;
  color: #000;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-icon {
  width: 36px;
  height: 15px;
  position: relative;
}
.notification-profile-icon {
  width: 36px;
  height: 15px;
  margin-right: -10px;
  position: relative;
}
.report-style-class {
  height: 100vh;
}
.icon-list-width {
  min-width: 30px !important;
}
.icon-list-width:hover {
  background-color: #90ca41;
}
.Mui-selected {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}
::ng-deep .font-14 {
  font-size: 14px !important;
}
.color-FFAE00 {
  background-color: #ffae00 !important;
}
.hover-sub-menu:hover {
  color: #90ca41;
  cursor: pointer;
}
.drawer-close {
  position: relative;
  left: 204px;
  top: -22px;
  color: #fff;
}
.drawer-close:hover {
  position: relative;
  left: 204px;
  top: -22px;
  color: #90ca41;
  cursor: pointer;
}
.font-14 {
  font-size: 14px;
}
.btn-avatar {
  color: #000 !important;
  font-size: 21px !important;
  border-radius: 61% !important;
  border: 2px solid #fff !important;
  height: 40px !important;
  min-width: 19px !important;
  padding: 10px !important;
  background-color: #fff !important;
  margin-left: 20px !important;
  font-weight: 700 !important;
}
.MuiBackdrop-root {
  width: 100% !important;
}
.mt-66 {
  margin-top: 66px !important;
}
.css-1u2w381-MuiModal-root-MuiDrawer-root {
  width: 0px !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  width: 0px !important;
}
.card {
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
  /* border-top: 3px solid #000 !important; */
}
.card:hover{
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
}
.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  color: #000 !important ;
  background-color: rgba(0, 0, 0, 0);
  /* border-bottom: 0px solid rgba(0, 0, 0, 0.125); */
}
.card-body {
  padding: 20px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 4px);
  padding: 0.625rem 1.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  /* border-radius: 0; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.color-000 {
  color: #000 !important;
}
.bg-color-282c34 {
  background-color: #fff !important;
  color: #000 !important;
}
.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
.header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed.css-101qe9k{
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-i6s8oy {
    background-image: url(/static/media/pexels-photo.febdd857.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff!important;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-i6s8oy {
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff!important;
}
.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
.css-hyum1k-MuiToolbar-root {  
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
.color-fff {
  color: #fff !important;
}
.inner-div {
  width: 100%;
  margin: 0px auto;
  padding-left: calc(64px + 1px);
}
.btn-submit {
  color: #fff !important;
  background-color: #1f1f1f !important;
}

.btn-submit:hover {
  color: #000 !important;
  background-color: #90ca41 !important;
}
.h-admin {
  height: 100%;
}
.card-admin-div {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-in-out;
  background-color: #fff;
  /* border-radius: 5px; */
  /* border-top: 3px solid #000 !important; */
}
.btn-icon {
  color: #000 !important;
}
.btn-icon:hover {
  cursor: pointer;
  color: #90ca41 !important;
}
.font-label {
  font-size: 24px;
}
.btn-error {
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
}
.btn-error:hover {
  color: #fff !important;
  background-color: #000 !important;
  border: 1px solid #fff !important;
}
.ml-open-drawer{
  margin-left:12.5%;
}
.btn-round-dashboard {
  color: #fff !important;
  font-size: 26px !important;
  /* border-radius: 61% !important; */
  min-width: 19px !important;
  padding: 10px !important;
  background-color: #84b93d  !important;
  margin-left: 20px !important;
}
.btn-round-dashboard:hover {
  color: #fff !important;
  font-size: 26px !important;
  /* border-radius: 61% !important; */
  min-width: 19px !important;
  padding: 10px !important;
  background-color: #1f1f1f !important;
  margin-left: 20px !important;
  font-weight: 700 !important;
}
.color-000{
  color: #000 !important;
}
.treeview-display{
    display: contents;
}
.css-qf5yqa-MuiDrawer-docked .MuiDrawer-paper{
  background-color: #000 !important;
  color: #fff !important;
}
.css-uqx0qm-MuiDrawer-docked .MuiDrawer-paper{
  background-color: #000 !important;
  color: #fff !important;

}
.bg-bluebtn-sidebar{
  outline: 0px;
    border: 0px;
    border-radius: 0px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: middle;
    -webkit-appearance: none;
            appearance: none;
    color: inherit;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-width: 0px;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 16px;
    /* transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    background-color: #ffff !important;
    margin: 12px;
    color: #000;
}
.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  white-space: break-spaces !important;
}
.m-auto-avatar{
  margin: 0px auto !important;
}
.css-1g86id8-MuiTreeItem-content.Mui-selected{
  background-color : transparent !important;
}
.Mui-selected {
  color: #e3e3e3 !important;
  border-bottom: 1px solid #000 !important;
}
.expand-icon{
  top: 0px;
  position: relative;
  margin-right: 2px;
}
.home-icon-expand{
  left: 25px;
  position: absolute;
}
.home-icon-expand-sub-li{
  left: 5x !important;
  position: absolute;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}
.break-word-sidebar{
  width: 200px;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 12px;
}
.css-bpnhhg .MuiDrawer-paper {
  
  background-color: #000 !important;
}
.mui-fixed{
  background-color: #000 !important;
}
.css-eil3g9 .MuiDrawer-paper{
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
.css-1q2h7u5.Mui-selected {
  color: #000 !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1l8j5k8 {

  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
  }
  .popper {
    display: inline-block;
    position: relative;
    top: 55px;
    left: calc(100vw - 145%);

    z-index: 99999999;
  }
.MuiPopper-root{
  z-index: 999999999;

  }
  .MuiTreeItem-label{
    padding-left: 0px !important;
  }
  .css-cgnp2o{
    padding: 0px !important;
    padding-top: 24px !important;
  }
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background-image: url(/static/media/pexels-photo.febdd857.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.css-1g86id8-MuiTreeItem-content .MuiTreeItem-iconContainer{
  width: 5px !important;
}
.css-19kzrtu {
  padding: 0px !important;
}

.css-yuu8jj .MuiTreeItem-iconContainer{
  width: 5px !important;
}
.icon-div{
  width: 20px !important;
  height: 20px !important;
  padding: 1px;
  border-radius: 50% !important;
  background-color: #fff !important;
}
.bg-yellow{
  border: 3px solid #ffae00 !important;
}
.icon-size-header{
  width: 35px !important;
  height: 35px !important;
}
.loader-css{
  margin: 0px auto;
  padding-left: 35.5%;
  padding-top: 10%;
  position: absolute;
  z-index: 999999;
}
.css-1q2h7u5.Mui-selected {
  color: #8cc63f !important;
}
.font-side-sidebar{
    font-size: 14px !important;
  font-family: sans-serif !important;
  font-weight: normal !important;
}
.border-login-inner-div{
  border: 2px solid #95d228;
  padding: 4rem;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  font-size: 12px !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #8cc63e !important;
}
body {
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: sans-serif !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: #fff;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  font-size: 12px !important;
}
.css-10hburv-MuiTypography-root{
  font-size: 12px !important;
}
.font-side-sidebar .css-10hburv-MuiTypography-root{
  font-size: 14px !important;
}
.color-00153f {
  color: #00153f !important;
}
.css-w5z3pe{
  background-color: #fff !important
}
.logoBar {
  height: 36px;
  background-color: #ffffff !important;
  border-top: 1px solid #ffffff;
  border-top: 1px solid var(--neutral-tertiary-alt-color,#ffffff);
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 200;
}
/* .css-1jnrh0n-MuiTableCell-root.MuiTableCell-head{
  background-color: #3f3f41 !important
} */
.Admin-textBox {
  border-radius: 0px !important;
  border-color: #000 !important;
  border-width: 2px !important;
}
.Admin-textBox fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #000 !important;
}
.Admin-textBox .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1v4ccyo{
  border: 1px solid #000 !important;
}
label {
  background-color: #fff;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #92c64d !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  color: #000 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:invalid{
  color: #fff !important;
}
.Admin-textBox .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:invalid{
  color: #000 !important;
}
.mt-report{
 margin-top: 4.2rem!important
}
.Admin-textBox .MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 0px !important;
  border-color: #fff !important; 
  border-width: 2px !important;
  color: #000 !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(139 198 61) !important;
}
.report-text-div{
  font-size: 13px;
}
.report-text-div:hover{
  font-size: 13px;
  cursor: pointer;
  color: #84b93d;
}
.css-1km1ehz.Mui-selected{
  background-color: #84b93d !important;
}
.css-fvipm8{
  border-radius: 4px !important;
  border: 1px solid !important;
}.pagination > li {
  list-style: none;
  border: 0.3px solid;
}
.pagination > li > a,
.pagination > li > span {
  float: left;
  padding: 8px 20px;
  line-height: 1.5;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.pagination > li > a:hover {
  background-color: #218838;
  color: white;
  cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}
button, input, optgroup, select, textarea,label{
  font-size: 12px !important;
}
.pagination-data{
  display: flex !important;
    list-style: none !important;
}
.pagination-btn{
  color: #fefeff;
    background-color: #1f1f1f;
    border: 1px solid !important;
    line-height: 1.5;
    border-radius: 0.25rem;
}
.th-style{
  font-family: sans-serif !important;
  width: 357px !important;
  padding: 1px !important;
  font-size: 12px !important;
  background-color: #605e5c !important;
  line-height:1rem !important;
}
.td-style{
  font-family: sans-serif !important;
  width: 357px !important;
  padding: 2px 5px !important;
  font-size: 12px !important;
  line-height: 1rem;
  letter-spacing: 0rem;
}
.head-table-div{
  background-color: #605e5c !important;
}
.date-style-div{  
  box-shadow: 0px 3px 10px rgb(96 94 92 / 70%) !important;
  width: auto !important;
  margin: 0px 9px 3px 0px !important;
  padding: 0px 5px 6px 5px !important;
}
.table-div{
  
  display: table !important;
  width: 100vw !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  min-width: 700px !important;
}
.label-date{
  font-size: 10px !important;
}
.po-width{
  width: 700px  !important;
}
.table-container-div{
  box-shadow: 0px 3px 10px rgb(96 94 92 / 70%) !important;
padding: 5px !important;    
height: 72vh !important;
}
.css-1xr9krm.Mui-selected {
  background-color: #605e5c !important;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: #605e5c !important;
}
.total-page-div{
  
  font-size: 12px;
  font-weight: 600;
  padding-top: 7px;

}
.table-height{
  height: 71vh !important;
}
.font-header{
  font-family: unset;
  font-stretch: ultra-expanded;

}.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
  z-index: 999999 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
